@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Regular.eot");
	src: local("Gilroy Regular"), local("Gilroy-Regular"),
		url("../fonts/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Gilroy-Regular.woff2") format("woff2"), url("../fonts/Gilroy-Regular.woff") format("woff"),
		url("../fonts/Gilroy-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Bold.eot");
	src: local("Gilroy Bold"), local("Gilroy-Bold"), url("../fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Gilroy-Bold.woff2") format("woff2"), url("../fonts/Gilroy-Bold.woff") format("woff"),
		url("../fonts/Gilroy-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Light.eot");
	src: local("Gilroy Light"), local("Gilroy-Light"), url("../fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Gilroy-Light.woff2") format("woff2"), url("../fonts/Gilroy-Light.woff") format("woff"),
		url("../fonts/Gilroy-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Gilroy";
	src: url("../fonts/Gilroy-Medium.eot");
	src: local("Gilroy Medium"), local("Gilroy-Medium"),
		url("../fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
		url("../fonts/Gilroy-Medium.woff2") format("woff2"), url("../fonts/Gilroy-Medium.woff") format("woff"),
		url("../fonts/Gilroy-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Gotham Pro";
	src: url("../fonts/GothamPro-Bold.eot");
	src: url("../fonts/GothamPro-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/GothamPro-Bold.woff2") format("woff2"), url("../fonts/GothamPro-Bold.woff") format("woff"),
		url("../fonts/GothamPro-Bold.ttf") format("truetype"),
		url("../fonts/GothamPro-Bold.svg#GothamPro-Bold") format("svg");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
